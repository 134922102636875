import React from 'react';
import Layout from '../components/Layout';
import Seo from "../components/seo";
import Video from '../components/Video';

const VideoPage = () => {

  return (
    <Layout
      clsOverflow={false}
    >
      <Seo
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
      />

      <Video/>

    </Layout>
  )
}

export default VideoPage;
