import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export const ContVideo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const MyAniLink = styled(AniLink)`
  display: none;
`
