import React, {useEffect} from 'react';
import {ContVideo} from './Video.styles';
import VideoFile from '../../media/presentacion_el_club.mp4';
import {GlobalBackgroundColors} from '../../styles/GlobalStyles';
import {MyAniLink} from './Video.styles';

const Video = () => {

  useEffect(() => {
    document.getElementById('myVideo').addEventListener('ended',handlerFinished,false);
    function handlerFinished(e) {
      console.log('TERMINÓ');
      document.getElementById('goTo').click();
    }
  }, []);

  return (
    <ContVideo>
      <video id="myVideo" controls>
        <source src={VideoFile} type="video/mp4" />
        <track label="Español" kind="subtitles" srcLang="es" src="" />
      </video>
      <MyAniLink
        id={'goTo'}
        paintDrip
        hex={GlobalBackgroundColors.bgContentLight}
        to={'/bordados'}
      >&nbsp;</MyAniLink>
    </ContVideo>
  )
}

export default Video;
